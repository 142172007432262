import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import Slider1 from './components/Slider1';
import { BrowserRouter as Router, Routes, Route, useLocation} from "react-router-dom";
import Slider2 from './components/Slider2';
import Slider3 from './components/Slider3';
import ControlAutoplay from './components/ControlAutoplay';
import Slider4 from './components/Slider4';
import Slider5 from './components/Slider5';
import Slider6 from './components/Slider6';
import Loading from './components/Loading';

function App() {
    const [autoplay, setAutoplay] = useState(false);
    const [loading1 , setLoading1] = useState(false)

  useEffect(()=>{
    const interval = setInterval(() => {
      const autoState = localStorage.getItem("autoplay");
      if (autoState === "true") {
        setAutoplay(true)
        setLoading1(true)
        // clearInterval(interval);
      }else{
        setLoading1(false)

      }
    },5000)
  },[autoplay, setAutoplay])

  return (
    
    <div className="App">
    <Router>
       <Routes>
      
        <Route path='/slider1' element={loading1 ? <Slider1/> : <Loading/>}/>
        <Route path='/slider2' element={loading1 ? <Slider2/> : <Loading/>}/>
        <Route path='/slider3' element={loading1 ? <Slider3/> : <Loading/>}/>
        <Route path='/slider4' element={loading1 ? <Slider4/> : <Loading/>}/>
        <Route path='/slider5' element={loading1 ? <Slider5/> : <Loading/>}/>
        <Route path='/slider6' element={loading1 ? <Slider6/> : <Loading/>}/>
      
      <Route path='/' element={<ControlAutoplay/>}/>

      </Routes>    
    </Router>
    </div>
  );
}

export default App;



