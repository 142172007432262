import { useRef } from 'react';

export function useSlickSlider() {
  const sliderRef = useRef();
  
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    arrows: false,
    centerMode: false,
    vertical: true,
  };
  return { sliderRef, settings, };
}


