import React, { useRef, useState } from "react";

const ControlAutoplay = () => {
  const inputRef = useRef(null);

  const handlePlayClick = () => {
    localStorage.setItem("autoplay", true);
  };

  const handlePauseClick = () => {
    localStorage.setItem("autoplay", false);
  };

  // const iframeData = [
  //   "http://localhost:3000/slider1",
  //   "http://localhost:3000/slider2",
  //   "http://localhost:3000/slider3",
  //   "http://localhost:3000/slider4",
  //   "http://localhost:3000/slider5",
  //   "http://localhost:3000/slider6",
  // ];

  return (
    <div>
      <div className="ControlContainer">
        <div className="display">
          {/* display */}
          {/* <div className="grid-container">
            {iframeData.map((url, index) => (
              <div className="grid-item" key={index}>
                <iframe src={url} title={`description ${index}`}></iframe>
              </div>
            ))}
          </div> */}
        </div>



        <div className="control">
          <div className="breadCrumb">
            <h2>Control</h2>
          </div>
          <div className="controlDiv">
            <button onClick={handlePlayClick} className="btn1">
              Start
            </button>
            <button onClick={handlePauseClick} className="btn2">
              Stop
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlAutoplay;


