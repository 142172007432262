import React from 'react'
import ReactLoading from 'react-loading';
import './slickStyle.css'

const Loading = () => {
    const type = 'bars'
    const color = 'blue'
  return (
    <div className='loaderContainer'>
        <div className='loaderDiv'>
        <ReactLoading type={type} color={color} className='loader' />
         <p>Please wait...</p>
        </div>
    </div>
  )
}

export default Loading