import React, { useContext, useEffect, useState } from 'react';
import Slider from "react-slick";
import './slickStyle.css'
import Image1 from './images/1.jpg'
import Image2 from './images/2.jpg'
import Image3 from './images/3.jpg'
import { useSlickSlider } from './SliderUtils';


const Slider1 = () => {
  const images = [
    Image1,
    Image2,
    Image3,
  ];
  const { sliderRef, settings, } = useSlickSlider();

  return (
    <div className="slider-container">
       <Slider  ref={sliderRef} {...settings} className='innerDiv'>
      {images.map((imageUrl, index) => (
        <div key={index} className='slick-slide'>
          <img src={imageUrl} alt={`Image ${index+1}`} />
        </div>
      ))}
      </Slider>
     
  </div> 
  )
}

export default Slider1;